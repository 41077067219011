import { PATH_NAME } from 'configs/pathName';
import { USER_ROLE } from 'configs/userRole';
import { extractSubdomain } from 'helpers/comman';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { handleLoginModal } from 'redux/actions/auth.action';
import { cricket, diageo, influencemobile } from 'routes/subdomains';

// services
import authService from 'services/authService';

const AuthGuard = ({ children }: any) => {
  const isAuth = authService.getAccessToken(); // Check if user is authenticated
  const user = authService.getUser() as any;
  const dispatch = useDispatch();
  const isLoginModalOpen = useSelector((state: any) => state.auth.isLoginModalOpen); // Check if login modal is open
  const subdomain = extractSubdomain(); // Extract current subdomain

  // Function to determine the redirection path based on subdomain
  const getRedirectPath = (subdomain: any) => {
    switch (subdomain) {
      case influencemobile:
        dispatch(handleLoginModal(true)); // Open login modal for 'influencemobile'
        return PATH_NAME.ROOT;
      case cricket:
        return PATH_NAME.CRICKET_SIGNIN;
      case diageo:
        return null; // No redirection for 'diageo'
      default:
        return PATH_NAME.ROOT; // Default redirection
    }
  };

  // Handle redirection based on user role or authentication status
  const redirectTo = () => {
    // Redirect admin users to dashboard
    if (user) {
      const userRole = user;
      if (userRole.role === USER_ROLE.ADMIN) {
        return <Redirect to={PATH_NAME.DASHBOARD} />;
      }
    }

    // If user is not authenticated, determine redirect path based on subdomain
    if (!isAuth && !isLoginModalOpen) {
      const redirectPath = getRedirectPath(subdomain);
      if (redirectPath) {
        return <Redirect to={redirectPath} />;
      }
    }

    return null; // No redirection needed
  };

  // Run redirect logic on component mount and on dependency changes
  useEffect(() => {
    redirectTo();
  }, [isAuth, isLoginModalOpen, dispatch, subdomain]);

  // Render redirection if needed, or children if authenticated
  return redirectTo() || <>{children}</>;
};

export default AuthGuard;
