import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';

class AuthService {
  constructor() {
    this.migrateStorageToCookies(); // Ensure migration happens on initialization
  }

  // Handle user authentication and session persistence
  handleAuthentication = () => {
    const accessToken = this.getAccessToken();
    if (!accessToken || !this.isValidToken(accessToken)) {
      this.logOut(); // Log out if token is invalid or expired
      return;
    }
    this.setSession('accessToken', accessToken); // Ensure token is set in cookies
  };

  // Login and store session details in cookies
  loginWithAuth0 = async (
    username: string,
    roleUser: string,
    code: string,
    accessToken: string,
    permission: any,
    refreshtoken: string,
  ) => {
    if (accessToken) {
      this.setSession('accessToken', accessToken);
      this.setSession('refreshtoken', refreshtoken);
    }

    if (permission) {
      this.setSession('permission', JSON.stringify(permission));
    }

    const user = {
      user: username,
      role: roleUser,
      code: code,
    };

    if (accessToken) {
      this.setSession('user', JSON.stringify(user));
    }

    return user;
  };

  loginWithCustomerUserAuth0 = async (userData: any, accessToken: string, campaign: any, customer: any) => {
    if (accessToken) {
      this.setSession('accessToken', accessToken);
    }
    const user = {
      user: userData,
      is_new: userData.is_new,
      customer_unique_id: userData.customer_unique_id,
      campaign,
      customer,
    };
    if (accessToken) {
      const userStringify = JSON.stringify(user);
      this.setSession('user', userStringify);
    }
    return user;
  };

  // Migrate localStorage data to cookies (only runs once on initialization)
  migrateStorageToCookies = () => {
    const accessToken = localStorage.getItem('accessToken');
    const user = localStorage.getItem('user');
    const permission = localStorage.getItem('permission');
    const refreshtoken = localStorage.getItem('refreshtoken');

    if (accessToken) {
      this.setSession('accessToken', accessToken);
      localStorage.removeItem('accessToken');
    }

    if (refreshtoken) {
      this.setSession('refreshtoken', refreshtoken);
      localStorage.removeItem('refreshtoken');
    }

    if (user) {
      this.setSession('user', JSON.stringify(user));
      localStorage.removeItem('user');
    }

    if (permission) {
      this.setSession('permission', JSON.stringify(permission));
      localStorage.removeItem('permission');
    }
  };

  // Set session details using cookies
  setSession = (key: string, value: string) => {
    Cookies.set(key, value, {
      expires: 7, // 7 days expiry
      path: '/', // Available throughout the site
      secure: process.env.NODE_ENV === 'production', // Only secure cookies in production
      sameSite: 'Strict', // Prevents CSRF attacks
      // HttpOnly is not set, meaning it can be accessed by JavaScript
    });
  };

  // Get access token from cookies
  getAccessToken = () => {
    const accessToken = Cookies.get('accessToken');
    if (accessToken && this.isValidToken(accessToken)) {
      return accessToken;
    }
    return null; // Explicitly return null when the token is invalid
  };

  getPermission = () => {
    const permission = Cookies.get('permission');
    const accessToken = Cookies.get('accessToken');
    if (accessToken && this.isValidToken(accessToken) && permission) {
      return JSON.parse(permission);
    }
    return null; // Explicitly return null when the token is invalid
  };

  // Get user data from cookies
  getUser = () => {
    const user = Cookies.get('user');
    return user ? JSON.parse(user) : null;
  };

  // Check if the user is authenticated
  isAuthenticated = () => {
    return !!this.getAccessToken();
  };

  // Validate token expiration
  isValidToken = (accessToken: string | null) => {
    if (!accessToken) return false;
    try {
      const decodedToken: any = jwtDecode(accessToken);
      this.setSession('permission', JSON.stringify(decodedToken.permissions));
      return decodedToken.exp > Date.now() / 1000;
    } catch (error) {
      return false;
    }
  };

  // Log out the user (clear all cookies)
  logOut = () => {
    Cookies.remove('accessToken');
    Cookies.remove('user');
    Cookies.remove('permission');
    Cookies.remove('refreshtoken');
  };
}

const authService = new AuthService();
export default authService;
