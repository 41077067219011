import { USER_ROLE } from 'configs/userRole';
import authService from 'services/authService';

class PermissionService {
  getPermissionList = () => {
    const permission = authService.getPermission();
    if (permission) {
      const parsedPermission = permission;
      return parsedPermission;
    }
    return [];
  };
  findAbility = (module: string, permissionType: string) => {
    const permission = this.getPermissionList();
    const ability = permission.find((item: any) => item.module == module);
    if (ability) {
      return ability[permissionType];
    }
    return false;
  };
  shouldRestrict = (module: string, permissionType: string) => {
    const isallowed = this.findAbility(module, permissionType);
    return !isallowed;
  };
}

const permissionService = new PermissionService();

export default permissionService;
