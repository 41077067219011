import { PATH_NAME } from 'configs/pathName';
import { USER_ROLE } from 'configs/userRole';
import { Redirect } from 'react-router';
import authService from 'services/authService';

const AdminAccess = ({ children }: any) => {
  const isAuth = authService.getAccessToken();
  if (!isAuth) {
    return <Redirect to={PATH_NAME.ADMIN_LOGIN} />;
  }
  const user = authService.getUser();
  if (!user) {
    return <>{children}</>;
  }
  const userRole = user.role;
  if (userRole === USER_ROLE.ADMIN) {
    // if (permissionService.shouldRestrict(PERMISSION_MODULE.TERMS, PERMISSION_TYPE.ADD)) {
    //   return <Redirect to={PATH_NAME.DASHBOARD} />
    // }
    return <>{children}</>;
  }
  return <Redirect to={PATH_NAME.ADMIN_LOGIN} />;
};

export default AdminAccess;
